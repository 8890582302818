import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SecondaryHeader from "../components/SecondaryHeader"
import Collapsible from "react-collapsible"
import CustomLink from "../components/CustomLink"
import Helmet from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const AboutPage = () => {
  const RenderCollapsable = ({ title, description }) => {
    return (
      <Collapsible trigger={title}>
        <p
          style={{
            padding: "15px",
            borderBottomWidth: "1px",
            borderBottomColor: "#001A1C33",
            color: "#001A1C",
          }}
        >
          {description}
        </p>
      </Collapsible>
    )
  }

  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="AlignerBase More information: All your questions regarding pricing, platform features, case submission and treatment planning answered here!"
      />
      <Helmet>
        <meta charset="utf-8" />
      </Helmet>
      <Seo title={`More information`} />
      <SecondaryHeader title={`Frequently Asked Questions`} />

      <div id="faq-page">
        <section className="faq">
          {/* First section */}

          {/* Spacing */}
          <div style={{ paddingBottom: "50px" }}></div>
          <p>More information about our company and services</p>

          {/* Spacing */}
          <div style={{ paddingBottom: "50px" }}></div>
          <h3>General:</h3>

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>

          {[
            {
              title: "1. How long will it take to have my plans ready?",
              description:
                "We aim to make plans ready for download within 48-hours of case submission and acceptance, although in exceptional cases this may take longer (up to 7 business days). This timeline does not include revisions which may be done multiple times based on client discretion.",
            },
            {
              title: "2. Who will be designing the treatment plans?",
              description:
                "Our team of expert designers will. Our designers have collectively delivered more than 100,000 designs and worked for pioneering companies in the aligner market. All designers are qualified dental technicians, that have passed our mandatory screening test and are subject to 2-month training prior to be being onboarded.",
            },
            {
              title:
                "3. Will treatment plans be reviewed by an Orthodontist before finalization?",
              description:
                "Each AlignerBase plan passes through a detailed Quality Check performed by qualified Orthodontists before being shared with you. You may also directly message the QC Orthodontist working on your case through the AlignerBase Platform.",
            },
            {
              title:
                "4. Which classes of occlusion can be treated with AlignerBase treatment plans?",
              description:
                "Clear aligners have the potential to improve all types of occlusion, however, we cannot promise a perfect outcome. To determine if clear aligner therapy is suitable for a patient, the treating doctor should consider the overall treatment goal, the movements required, and the current state of the patient's dentition.",
            },
            {
              title:
                "5. Will attachments be used as part of the treatment plans?",
              description:
                "Yes, AlignerBase uses attachments in its treatment plans when required to apply force to move teeth into desired position (e.g., mesial rotation, bodily movements, extrusion, etc.) and improve the stability of the aligners. They are typically used for complex cases and help to increase surface area of tooth in contact with aligner making the treatment more precise and efficient.",
            },
            {
              title:
                "6. What treatment planning software is used by AlignerBase?",
              description: (
                <>
                  We primarily utilize Blue Sky Plan for our treatment designs,
                  but we also have sufficient licenses for 3Shape Ortho System.
                  Both platforms are industry leaders in dental CAD solutions
                  and have received the necessary FDA approvals. While these are
                  our standard tools, we remain open to adapting other software
                  based on client needs Please
                  <CustomLink title={"Contact Us"} link={"/contact-us"} />
                  to discuss any specific software preferences or requirements.
                </>
              ),
            },
          ].map((item, index) => (
            <RenderCollapsable
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}

          {/* Second section */}

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>
          <h3>Plans and Pricing:</h3>

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>

          {[
            {
              title:
                "7. What plans and pricing options are available for AlignerBase customers?",
              description: (
                <>
                  AlignerBase offers value-driven, market-beating pricing
                  through its two plans: Pay As You Go and Enterprise Plan.
                  Please view the
                  <CustomLink
                    title={"Plans and Pricing"}
                    link={"/plan-and-pricing"}
                  />
                  page for further details.
                </>
              ),
            },
            {
              title: "8. Is there a Free Trial for new AlignerBase customers?",
              description: (
                <>
                  Yes, AlignerBase is currently offering a Free Trial case to
                  all new customers on its Platform. This is for a limited time
                  only. Please view the
                  <CustomLink
                    title={"Plans and Pricing"}
                    link={"/plan-and-pricing"}
                  />
                  page for further details.
                </>
              ),
            },
            {
              title:
                "9. Is there a cost associated to use the AlignerBase platform?",
              description: (
                <>
                  The AlignerBase Platform is free to use for all new customers
                  who are still under the Pay As You Go Plan - the only fee
                  charged is the design fee per case at this stage.
                  <br />
                  <br />
                  Only customers subscribing to the Enterprise plan are charged
                  a One-Time Fee of USD $599. This amount is then fully credited
                  back to the account and can then be used towards monthly
                  invoices. Consider this as an up-front payment to unlock
                  preferential rates available under the Enterprise plan.
                  <br />
                  <br />
                  Please view the
                  <CustomLink
                    title={"Plans and Pricing"}
                    link={"/plan-and-pricing"}
                  />
                  page for further details.
                </>
              ),
            },
          ].map((item, index) => (
            <RenderCollapsable
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}

          {/* Third section */}

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>
          <h3>Platform Features:</h3>

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>

          {[
            {
              title:
                "10. Do I need to download any software to use the AlignerBase platform?",
              description:
                "No, the AlignerBase platform is web-based and can be accessed using web-browsers. We recommend using Google Chrome, but it can also be accessed on other mainstream browsers (e.g., Microsoft Edge, Safari)",
            },
            {
              title:
                "11. Is the AlignerBase platform available in other languages?",
              description:
                "The AlignerBase platform is only available in English at the moment. We are working on providing support for additional languages. Please Contact Us to provide feedback and suggestions.",
            },
            {
              title:
                "12. Does the AlignerBase Platform allow me to register additional users under the same account?",
              description:
                "Yes, Enterprise plan clients will have multi-user access to the AlignerBase platform and will be able to add their business details and additional Users. Pay As You Go clients will have a single-user access to the AlignerBase Platform.",
            },
          ].map((item, index) => (
            <RenderCollapsable
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>
          <h3>Submitting Cases:</h3>

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>

          {[
            {
              title:
                "13. What type of patient records are required for case submission?",
              description: (
                <>
                  You need to upload{" "}
                  <strong>Scans, Photos, and complete the Rx form:</strong>
                  <br />
                  <ul>
                    <li>
                      <strong>Scans</strong> are mandatory and need to be an
                      intra oral scan (which may be Maxillary, Mandibular or
                      both) which can be either obtained through an intra-oral
                      scanner or from a lab/desktop scan of an impression.
                      Please ensure files are in correct occlusion. Files
                      Supported include STL and DCM.
                    </li>
                    <li>
                      <strong>Photos</strong> are mandatory and include a
                      full-face (frontal) smiling image and 3 intra-oral images
                      including a right lateral, left lateral and frontal view.
                      You may also upload upper and lower occlusal views if
                      available.
                    </li>
                    <li>
                      <strong>X-Rays</strong> are not required but recommended
                      where cases have higher complexity. We accept panoramic
                      x-ray or a full mouth x-ray series (FMX).
                    </li>
                    <li>
                      <strong>Completing the Rx form is mandatory</strong>,
                      where you should mention all instructions regarding the
                      case, existing condition, treatment objectives, etc.
                    </li>
                    <li>
                      Please view{" "}
                      <CustomLink
                        title="Understanding the Orders Dashboard"
                        link="/support-center/the-orders-dashboard"
                      />{" "}
                      in the Support Center for further information about
                      submitting an order.
                    </li>
                  </ul>
                </>
              ),
            },
            {
              title: "14. What type of Scan files are accepted by AlignerBase?",
              description: (
                <ul>
                  <li>
                    STL and DCM file formats from all intra-oral or desktop
                    scanners are acceptable, ensuring the quality of scans is
                    good.
                  </li>
                  <li>
                    We are working on building direct integrations with various
                    intraoral scanners so that your will be able to directly
                    share scans with us without having to upload manually.
                  </li>
                </ul>
              ),
            },
            {
              title: "15. Are physical impressions or models acceptable?",
              description:
                "Not at the moment. We request you to only share STL scan files with us for now.",
            },
          ].map((item, index) => (
            <RenderCollapsable
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>
          <h3>Treatment Planning Stage:</h3>

          {/* Spacing */}
          <div style={{ paddingBottom: "20px" }}></div>

          {[
            {
              title:
                "16. How can I share my treatment preferences with AlignerBase so they are considered during designing?",
              description: (
                <>
                  You can select and set up your treatment preferences in the
                  AlignerBase Platform after signing in, from the 'Profile'
                  section (e.g., movement velocity, wear schedule, IPR timing,
                  etc.). Please view{" "}
                  <CustomLink
                    title="Understanding the Profile Dashboard"
                    link="/support-center/the-profile-dashboard"
                  />{" "}
                  in the Support Center for further information about treatment
                  preferences.
                </>
              ),
            },
            {
              title:
                "17. How can I share my production preferences with AlignerBase so they are considered during designing?",
              description: (
                <>
                  You can select and set up your production preferences in the
                  AlignerBase Platform after signing in, from the 'Profile'
                  section (e.g., ID Tag Placement, Tray Numbering, etc.). Please
                  view{" "}
                  <CustomLink
                    title="Understanding the Profile Dashboard"
                    link="/support-center/the-profile-dashboard"
                  />{" "}
                  in the Support Center for further information about treatment
                  preferences.
                </>
              ),
            },
            {
              title: "18. How can I view the status of my case?",
              description: (
                <>
                  You can view the stage a specific case is at by going to the
                  Orders page in dashboard and searching for the relevant case.
                  You will also receive a notification email as your case passes
                  through each of the various stages of treatment planning. This
                  setting can be disabled by the user through the Profile
                  section. Please view{" "}
                  <CustomLink
                    title="Understanding the Main Dashboard"
                    link="/support-center/the-main-dashboard"
                  />{" "}
                  in the Support Center for further information about submitting
                  an order.
                </>
              ),
            },
            {
              title:
                "19. How can I review, request revision, approve, or download the treatment plan?",
              description: (
                <>
                  Clients can perform all the above through the secure, cloud
                  based AlignerBase Platform. Once a treatment plan is ready,
                  you will have access to a detailed treatment plan report in
                  PDF format as well as a stage-by-stage video of movements,
                  which you can use to approve a plan or send back for revision
                  based on your comments/feedback. Please view{" "}
                  <CustomLink
                    title="Understanding the Orders Dashboard"
                    link="/support-center/the-orders-dashboard"
                  />{" "}
                  in the Support Center for further information about submitting
                  an order.
                </>
              ),
            },
            {
              title:
                "20. Does AlignerBase also provide a 3D visualization of the treatment plan?",
              description:
                "We are working on developing an interactive, high-resolution 3D visualization tool that will allow clients to view a detailed stage-by-stage 3D representation of the treatment plan. This should be available to our clients free of charge in the near future.",
            },
            {
              title: "21. How can I cancel a case?",
              description: (
                <>
                  Clients may cancel an order prior to finalization through the
                  Orders page of the AlignerBase platform. An order that is
                  cancelled while still at the 'Draft' stage will have no
                  charge; however, if an order is cancelled after it has been
                  accepted and designing has begun, a cancellation fee of USD
                  $35 will be charged. Please view the
                  <CustomLink
                    title={"Plans and Pricing"}
                    link={"/plan-and-pricing"}
                  />
                  page for further details.
                </>
              ),
            },
            {
              title: "22. What is a Revision?",
              description:
                "'Revision' implies a request to adjust the plan prior to approval, during the treatment planning phase and before the treatment begins.",
            },
            {
              title:
                "23. How many times can the treatment plan be revised prior to final approval?",
              description: (
                <>
                  Client may request treatment Revision as many times as
                  required to finalize the treatment plan, within reasonable
                  limits. Revisions are provided free of charge. Please view the
                  <CustomLink
                    title={"Plans and Pricing"}
                    link={"/plan-and-pricing"}
                  />
                  page for further details.
                </>
              ),
            },
            {
              title: "24. What is a Refinement?",
              description:
                "'Refinement' implies a request for a change to the treatment plan (mid-course correction) after the plan has been approved/finalized and patient has begun aligner treatment.",
            },
            {
              title: "25. How much does a Refinement cost?",
              description: (
                <>
                  Customers will be given 1 free Refinement for each case.
                  Additional Refinements will be charged at USD $35. Please view
                  the{" "}
                  <CustomLink
                    title={"Plans and Pricing"}
                    link={"/plan-and-pricing"}
                  />{" "}
                  page for further details.
                </>
              ),
            },
          ].map((item, index) => (
            <RenderCollapsable
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}
        </section>
      </div>
    </Layout>
  )
}

export default AboutPage
